import { create } from "zustand";

export const useBoundStore = create((set) => ({
  showPledge: false,
  setShowPledge: (value) => set({ showPledge: value }),
  showVolunteer: false,
  setShowVolunteer: (value) => set({ showVolunteer: value }),
  showMenu: true,
  setShowMenu: (value) => set({ showMenu: value }),
  showLoading: true,
  setShowLoading: (value) => set({ showLoading: value }),

}));

