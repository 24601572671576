import React, { useState } from "react";
import styled from "styled-components";
import { useBoundStore } from "../../store/useBoundStore";
import CloseIcon from "../../assets/svg/close-icon.inline.svg";
import VolunteerForm from "./VolunteerForm";
import LakeImg from "../../assets/images/volunteer-circle.png"

const Background = styled.div`
  position: fixed;
  z-index: 1200;
  top: 0;
  backdrop-filter: blur(1rem);
  height: 100vh;
  width: 100vw;
`;

const Holder = styled.div`
  position: fixed;
  height: auto;
  width: 90%;
  top: 5%;
  left: 5%;
  border-radius: 1.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  overflow: scroll;
  background: white;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: 100;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    height: 90%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;
  height: 100%;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 2fr 3fr;
    align-content: stretch;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  cursor: pointer;
  display: flex;
  border: none;

  svg {
    height: 1rem;
  }
`;

const TextCol = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background: linear-gradient(-45deg, #f6edf0, #d3e5ff);
  display: flex;
    flex-direction: column;
    justify-content: space-between;
  &.hasSubmitted {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h2,
  .h2 {
    margin: 0;
   ${(props) => props.theme.fluidType(4)};
    color: #1e75f8;

    &.thankyou {
      ${(props) => props.theme.fluidType(6)};

    }
  }
  .h2 {
    margin: 0 auto;
    width: 100%;
    width: 85%;
    text-align: center;

    @media (${(props) => props.theme.breakpoints.md}) {
      width: 80%;
    }

    @media (${(props) => props.theme.breakpoints.xl}) {
      width: 65%;
    }
  }
`;

const Images = styled.div`
  gap: 0.2rem;
  margin-top: 1.2rem;

  display: none;

@media (${(props) => props.theme.breakpoints.lg}) {
  display: flex;
}
`

const ImgHolder = styled.div`
  justify-self: end;
  img {
    height: 2rem;
    width: 4rem;
  }
`;

const Title = styled.div``;

const Text = styled.div`
  p {
    20px;
    line-height: 1.2;
  }
`;
const VolunteerFormOverlay = () => {
  const setShowVolunteer = useBoundStore((state) => state.setShowVolunteer);
  const showVolunteer = useBoundStore((state) => state.showVolunteer);
  const [submitMessage, setSubmitMessage] = useState(null);

  const handleClose = () => {
    setShowVolunteer(false);
  };


  if (!showVolunteer) return null;

  return (
    <Background>
      <Holder>
        {submitMessage && (
          <TextCol className="hasSubmitted">
            <p className="h2 thankyou">{submitMessage}</p>
          </TextCol>
        )}
        {!submitMessage && (
          <Grid>
            <TextCol>
              <div>
                <Title>
                <h2>Volunteer</h2>
              </Title>
              <Text>
                <p>Join the team working to restore the Great Salt Lake to 4,200'. 
                </p>
                <p>
                Fill out the form and we'll reach out with volunteer opportunities in your area.</p>
              </Text>
              </div>
              <Images>
                 <ImgHolder>
                <img
                  src={LakeImg}
                  alt="lake from above"
                />
              </ImgHolder>
              </Images>
             
            </TextCol>
            <VolunteerForm
       
              submitHandler={(message) => setSubmitMessage(message)}
            />
          </Grid>
        )}
        <CloseButton className="icon" onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>
      </Holder>
    </Background>
  );
};

export default VolunteerFormOverlay;

