import React from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import PropTypes from "prop-types";
import {PrismicRichText} from "@prismicio/react";
import addToMailchimp from "gatsby-plugin-mailchimp";

const Holder = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

`;

const InputHolder = styled.div`
  display: flex;
  gap: 0.4rem;
`

const ActionHolder = styled.div`
  margin-left: 1.9rem;

  a {
    color: var(--blue);
    font-weight: normal;
  }
`

const Pledge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;

  @media (${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
  }

  label {
    ${(props) => props.theme.fluidType(2)};
    line-height: 1;
    white-space: nowrap;
    margin-right: 0.5rem;
    display: flex;
    align-self: center;
    min-width: 5rem;

    @media (${(props) => props.theme.breakpoints.md}) {
      ${(props) => props.theme.fluidType(1)};
    }
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: flex;
    align-self: center;
    width: 0.8rem;
    height: 0.8rem;
    border: 1px solid #000; /* Add a border for the checkbox */
    outline: none; /* Remove the default focus outline */
    vertical-align: middle;
   
    cursor: pointer;
    border-radius: 50%;
  }

  input[type="checkbox"]:checked {
    background-color: #007bff; /* Change the background color when checked */
    border-color: white; /* Change the border color when checked */
    outline: 1px solid #007bff;
  }

  p {
    margin: 0;
    ${(props) => props.theme.fluidType(-1)};

    @media (${(props) => props.theme.breakpoints.md}) {
      ${(props) => props.theme.fluidType(-3)};
    }
  }
`;

const Details = styled.div`
  display: flex;
flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
  
  @media (${(props) => props.theme.breakpoints.md}) {
flex-direction: row;
  }

  .hidden {
    display: none;
  }
`;

const Detail = styled.div`
  position: relative;

  input {  
    height: 2rem;
    ${(props) => props.theme.fluidType(-1)};
  }

  .errorMsg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5rem;
    width: auto;

    ${(props) => props.theme.fluidType(-3.5)};

    @media (${(props) => props.theme.breakpoints.md}) {
      top: -1.2em;
      left: 0;
    }
  }


  

  &.submit {

    input[type="submit"] {
      width: 100%;
    }
  }
`;

const LowerDetails = styled.div`
display: grid;
grid-template-columns: 40% 1fr;
gap: 1rem;

@media (${(props) => props.theme.breakpoints.md}) {
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

`

const Statement = styled.div`
display: none;

@media (${(props) => props.theme.breakpoints.lg}) {
  display: block;
}

  p {
    line-height: 1;
    margin: 0;
    margin-bottom: 1.5rem;
    ${(props) => props.theme.fluidType(4)};
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("* Name is Required."),
  email: Yup.string()
    .email("* Enter a Valid Email.")
    .required("* Email is Required."),
  state: Yup.string(),
});



function PledgeForm({data, submitHandler, thanks}) {

  const pledges = data.map((pledge) => pledge.pledge_action.document.data.title.text);

  return (
    <Holder>
      <Formik
        initialValues={{name: "", email: "", state: "", pledge: pledges}}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting}) => {
          addToMailchimp(values.email, {
            NAME: values.name,
            STATE: values.state,
            PLEDGES: values.pledge,
          }).then((data) => {
            if (data.result === "success") {
              submitHandler(thanks.text);
              setSubmitting(false);
            } else {
              submitHandler(
                "Something went wrong! Could you please try again or email us directly."
              );
              setSubmitting(false);
            }
          });
        }}
      >
        {({isSubmitting}) => (
          <Form
            className="form"
            method="post"
            action="/"
            name="pledge-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="pledge-form" />
            <Statement>
              <p className="h2">I will...</p>
            </Statement>
            <div className="pledges">
              {data.map((pledge, i) => {
                const id = pledge.pledge_action.document.id;
                const {title, text} = pledge.pledge_action.document.data;
                return (
                  <Pledge key={i}>
                    <InputHolder>
                      <Field
                        className="input"
                        type="checkbox"
                        name="pledge"
                        value={title.text}
                      />
                      <label htmlFor={id}>{title.text}</label>
                    </InputHolder>
                    <ActionHolder>
                      <PrismicRichText field={text.richText} />
                    </ActionHolder>
                  </Pledge>
                );
              })}
            </div>
            <Details>
              <Detail>
                <Field
                  className="input"
                  type="text"
                  name="name"
                  placeholder="Name"
                />
                <ErrorMessage
                  className="errorMsg"
                  name="name"
                  component="small"
                />
              </Detail>
              <Detail className="email">
                <p className="hidden">
                  <label>
                    Don’t fill this out if you’re human:{" "}
                    <input name="bot-field" />
                  </label>
                </p>
                <Field
                  className="input"
                  type="email"
                  name="email"
                  placeholder="Email"
                />
                <ErrorMessage
                  className="errorMsg"
                  name="email"
                  component="small"
                />
              </Detail>
              <LowerDetails>
                  <Detail className="state">
                <Field
                  className="input"
                  type="text"
                  name="state"
                  placeholder="State"
                />
                <ErrorMessage
                  className="errorMsg"
                  name="state"
                  component="small"
                />
              </Detail>
              <Detail className="submit">
                <input
                  name="submit"
                  type="submit"
                  disabled={isSubmitting}
                  value="Submit"
                  className="blue"
                />
              </Detail>
              </LowerDetails>
            
            </Details>
          </Form>
        )}
      </Formik>
    </Holder>
  );
}

PledgeForm.propTypes = {
  data: PropTypes.array,
  submitHandler: PropTypes.func,
};

export default PledgeForm;

