import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const Details = styled.div`
  display: flex;
flex-direction: column;
  gap: 0.5rem;
  margin-top: 0;
  
  @media (${(props) => props.theme.breakpoints.md}) {
flex-direction: row;
margin-top: 2rem;

  }

  .hidden {
    display: none;
  }
`;

const Detail = styled.div`
  position: relative;

  input {  
    height: 2rem;
    ${(props) => props.theme.fluidType(-1)};
  }

  .errorMsg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5rem;
    width: auto;
    
    ${(props) => props.theme.fluidType(-3.5)};

    @media (${(props) => props.theme.breakpoints.md}) {
      top: -1.2em;
      left: 0;
    }
  }


  

  &.submit {

    input[type="submit"] {
      width: 100%;
    }
  }
`;

const LowerDetails = styled.div`
display: grid;
grid-template-columns: 40% 1fr;
gap: 1rem;

@media (${(props) => props.theme.breakpoints.md}) {
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

`

const Statement = styled.div`

display: none;

@media (${(props) => props.theme.breakpoints.lg}) {
  display: block;
}
  
  p {
    line-height: 1;
    margin: 0;
    margin-bottom: 1.5rem;
   ${(props) => props.theme.fluidType(4)};
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("* Name is Required."),
  email: Yup.string()
    .email("* Enter a Valid Email.")
    .required("* Email is Required."),
  state: Yup.string(),
});

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

function VolunteerForm({ submitHandler }) {
  return (
    <Holder>
      <Formik
        initialValues={{ name: "", email: "", state: "", volunteer: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": "volunteer-form",
              ...values,
            }),
          })
            .then(() => {
              submitHandler("Thank you for supporting the Great Salt Lake");
              setSubmitting(false);
            })
            .catch((error) => {
              submitHandler(
                "Something went wrong! Could you please try again or email us directly."
              );
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form
            className="form"
            method="post"
            action="/"
            name="volunteer-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="volunteer-form" />
            <Statement>
              <p className="h2">Sign Me Up!</p>
            </Statement>
            <Details>
              <Detail>
                <Field
                  className="input"
                  type="text"
                  name="name"
                  placeholder="Name"
                />
                <ErrorMessage
                  className="errorMsg"
                  name="name"
                  component="small"
                />
              </Detail>
              <Detail>
                <p className="hidden">
                  <label>
                    Don’t fill this out if you’re human:{" "}
                    <input name="bot-field" />
                  </label>
                </p>
                <Field
                  className="input"
                  type="email"
                  name="email"
                  placeholder="Email"
                />
                <ErrorMessage
                  className="errorMsg"
                  name="email"
                  component="small"
                />
              </Detail>
                <LowerDetails>
              <Detail className="state">
                <Field
                  className="input"
                  type="text"
                  name="state"
                  placeholder="State"
                />
                <ErrorMessage
                  className="errorMsg"
                  name="state"
                  component="small"
                />
              </Detail>
              <Detail className="submit">
                <input
                  name="submit"
                  type="submit"
                  disabled={isSubmitting}
                  value="Submit"
                  className="blue"
                />
              </Detail>
            </LowerDetails>

            </Details>
          </Form>
        )}
      </Formik>
    </Holder>
  );
}

VolunteerForm.propTypes = {
  submitHandler: PropTypes.func,
};

export default VolunteerForm;

