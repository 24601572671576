import React, {useEffect} from "react";
import styled from "styled-components";
import NavIcon from "../../assets/svg/nav-icon.inline.svg";
import PropTypes from "prop-types";
import {useState} from "react";
import Navigation from "../atoms/Navigation";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {throttle} from "lodash";
import {useWindowSize} from "react-use";
import marqueeWhite from "../../assets/images/campaign-marquee-white.png";
import marqueeDark from "../../assets/images/campaign-marquee-grey.png";
import mobileNavLogo from "../../assets/images/nav-logo-white.png";
import mobileNavLogoBlack from "../../assets/images/nav-logo.png";
import {navigate} from "gatsby";
import {useBoundStore} from "../../store/useBoundStore";

gsap.registerPlugin(ScrollTrigger);

const Holder = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  width: 100%;
  transform: ${(props) => (props.show ? "translateY(0)" : "translateY(-200%)")};
  transition: transform 0.5s ease-in-out, color 0.5s ease-in-out;
  color: ${(props) => (props.menuDark ? "rgb(55, 55, 55)" : "white")};
  display: grid;
  align-items: center;
  padding: 2rem 1rem 0.5rem 1rem;
  grid-template-columns: 12fr 1fr;
  gap: 1rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding-top: 0.5rem;
    grid-template-columns: 86fr 11fr 3fr;
  }

  .isHiddenOnMobile {
    display: none;
    @media (${(props) => props.theme.breakpoints.md}) {
      display: block;
    }
  }
  .isHiddenOnDesktop {
    display: block;
    @media (${(props) => props.theme.breakpoints.md}) {
      display: none;
    }
  }
`;

const Bg = styled.div`
  background: white;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: ${(props) => (props.menuHasBg ? 'none' : 'translateY(-100%)')};
  transition: transform 0.5s ease-in-out;
`;

const LockupHolder = styled.div`
  position: relative;
  .dark {
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${(props) => (props.menuDark ? 1 : 0)};
    transition: opacity 0.5s ease-in-out;
    width: 100%;
  }
  .light {
    opacity: ${(props) => (props.menuDark ? 0 : 1)};
    transition: opacity 0.5s ease-in-out;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Badge = styled.div`
  position: relative;
  z-index: 10;
  a {
    padding: 0.1vw 1.25vw 0 1.25vw;
    color: inherit;
    font-size: 1.25vw;
    line-height: 2;
  }
`;

const MenuButtonHolder = styled.div`
z-index: 20;
  button {
    width: 100%;
    @media( ${props => props.theme.breakpoints.md} ) {
      margin-top: 0.2rem;
    }
    svg {
      width: 100%;
      height: auto;
    }
  }
`;

const Header = ({article, home}) => {
  const size = useWindowSize();
  const [showNav, setShowNav] = useState(false);
  const setShowLoading = useBoundStore((state) => state.setShowLoading);


  const setShowMenu = useBoundStore((state) => state.setShowMenu);
  const showMenu = useBoundStore((state) => state.showMenu);

  const [menuDark, setMenuDark] = useState(true);
  const [menuHasBg, setMenuHasBg] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleClick = () => {
    if(home) {
        gsap.to(window, {
      duration: 1.9,
      scrollTo: 0,
    });
    } else {
      setShowLoading(false);
      navigate("/")
    }
  
  };

  /* eslint-disable */


  useEffect(() => {
    const onScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
     
      
      let heroHeight = size.height * 4;
if (!home) {
  heroHeight = size.height
}
      
          if (st > lastScrollTop && st > heroHeight) {
                  // downscroll
                  setShowMenu(false);
                } else {
                  // upscroll
                  setShowMenu(true);
                }

                if (st > size.height  && st < heroHeight) {
                  setMenuDark(false);
                } else {
                  setMenuDark(true);
                }

                if (st > heroHeight) {
                  setMenuHasBg(true);
                } else {
                  setMenuHasBg(false);
                }
      

      

      setLastScrollTop(st <= 0 ? 0 : st);
    };

    const throttledCount = throttle(onScroll, 100);
    window.addEventListener("scroll", throttledCount);
    return () => window.removeEventListener("scroll", throttledCount);
  }, [lastScrollTop, size]);

/* eslint-enable */


  const openHandler = () => {
    setShowNav(true);
  };

  const closeHandler = () => {
    setShowNav(false);
  };

  const lockupHolder = (
    <LockupHolder menuDark={menuDark}>
      <div className="dark">
        <img className="isHiddenOnMobile" src={marqueeDark} alt="marquee dark" />
        <img className="isHiddenOnDesktop" src={mobileNavLogoBlack} alt="marquee dark" />
      </div>
      <div className="light">
        <img className="isHiddenOnMobile" src={marqueeWhite} alt="marquee" />
        <img className="isHiddenOnDesktop" src={mobileNavLogo} alt="marquee" />
      </div>
    </LockupHolder>
  );

  return (
    <div>
      <Holder show={showMenu} menuDark={menuDark} className="navigation">
        <Bg menuHasBg={menuHasBg}/>
       <button
          className="strip-button-styles"
          onClick={() => handleClick()}>
        {lockupHolder}
        </button>
        <Badge className="isHiddenOnMobile">
          <a href="https://utahrivers.org/" className="button" target="_blank" rel="noopener noreferrer">A Utah Rivers
            Council Initiative</a>
        </Badge>

        <MenuButtonHolder>
          <button onClick={() => openHandler()} className="icon">
            <NavIcon />
          </button>
        </MenuButtonHolder>
      </Holder>
      <Navigation
        home={home}
        article={article}
        show={showNav}
        closeHandler={() => closeHandler()}
      />
    </div>
  );
};

Header.propTypes = {
  overlay: PropTypes.bool,
  article: PropTypes.bool,
  home: PropTypes.bool,
};

Header.defaultProps = {
  overlay: false,
  article: false,
};

export default Header;

