import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ScrollToPlugin } from "gsap/all";
import gsap from "gsap";
import CloseIcon from "../../assets/svg/close-icon.inline.svg";
import { useBoundStore } from "../../store/useBoundStore";
import {navigate} from "gatsby";
import {graphql, useStaticQuery} from "gatsby";

gsap.registerPlugin(ScrollToPlugin);

const Holder = styled.div`
  position: fixed;
  z-index: 1020;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: ${(props) => (props.show ? "translateX(0)" : "translateX(100%)")};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .spacer {
    position: fixed;
    z-index: 2;
    top: 0;
    backdrop-filter: blur(1rem);
    height: 100vh;
    width: 100vw;
  }
`;

const NavHolder = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  z-index: 5;

  @media (${(props) => props.theme.breakpoints.sm}) {
    width: auto;
  }
  background: white;
  z-index: 20;
  transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 0.25s;
  transform: ${(props) => (props.show ? "translateX(0)" : "translateX(100%)")};

  button {
    cursor: pointer;
  }
`;

const Inner = styled.div`
  background: linear-gradient(
    0deg,
    rgba(224, 215, 214, 0) 48.96%,
    rgba(229, 221, 233, 0.97) 100%
  );

  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.8rem;
  padding: 1rem 1rem 1rem 3rem;

  p {
    margin: 0;
    font-size: 22px;
  }

  .icon {
    svg {
      height: 100%;
      width: 1rem;
    }
  }

  button.nav-button,
  a {
    color: #1e75f8;
    text-decoration: none;

    font-size: 35px !important;
    transition: opacity 0.1s ease-out;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.8rem;

  .line {
    width: 3rem;
    margin: 0.5rem 0;
    border-bottom: 0.5px solid black;
  }
`;

const MoreLinks = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.8rem;
`;
const Navigation = (props) => {

  const setShowLoading = useBoundStore((state) => state.setShowLoading);

  const handleClick = (target) => {

    if(props.home) {
      props.closeHandler();
        gsap.to(window, {
      duration: 1.9,
      scrollTo: { y: `#${target}`, offset: "150px" },
    });
    } else {
      props.closeHandler();
      setShowLoading(false);
      (navigate("/"));
      
        gsap.to(window, {
          duration: 2.4,
          delay: 1,
          scrollTo: { y: `#${target}`, offset: "150px" },
        });
      
      
    }}
  

  const handlePledgeClick = () => {
    props.closeHandler();
    setShowPledge(true);
  };

  const handleVolunteerClick = () => {
    props.closeHandler();
    setShowVolunteer(true);
  };
  const setShowPledge = useBoundStore((state) => state.setShowPledge);
  const setShowVolunteer = useBoundStore((state) => state.setShowVolunteer);

  const data = useStaticQuery(graphql`
    query GlobalSettingsQuery {
      prismicGlobalSettings {
        _previewable
        data {
          project_guidebook {
            url
          }
        }
      }
    }
  `);

  const guidebookLink = data.prismicGlobalSettings?.data.project_guidebook?.url;


  return (
    <Holder show={props.show}>
      <NavHolder show={props.show}>
        <Inner>
          <button onClick={() => props.closeHandler()} className="icon">
            <CloseIcon />
          </button>
          <Actions>
            <p>Actions you can do</p>

            <a href="https://utahrivers.org/donate" 
              target="_blank"
              rel="noopener noreferrer"
              className="strip-button-styles"
            >
              Donate
            </a>
            <button
              className="strip-button-styles nav-button"
              onClick={() => handlePledgeClick()}
            >
              Pledge
            </button>
            <button
              className="strip-button-styles nav-button"
              onClick={() => handleVolunteerClick()}
            >
              Volunteer
            </button>
            <div className="line"></div>
          </Actions>
          <MoreLinks>
            <p>More about this Project</p>
           
              <>
                <button
                  className="strip-button-styles nav-button"
                  onClick={(e) => handleClick("policies")}
                >
                  See the Policies
                </button>
                <button
                  className="strip-button-styles nav-button"
                  onClick={(e) => handleClick("research")}
                >
                  Project Newsroom
                </button>
                <a
                  href={guidebookLink || '#'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Project Guidebook</span>
                </a>
                <a
                  href="https://utahrivers.org/"
                  rel="noreferrer"
                  target="_blank"
                >
                  About the URC
                </a>
              </>
           
          </MoreLinks>
        </Inner>
      </NavHolder>
      <div className="spacer"></div>
    </Holder>
  );
};

Navigation.propTypes = {
  closeHandler: PropTypes.func,
  show: PropTypes.bool,
  article: PropTypes.bool,
};

export default Navigation;

