import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../utils/fontface.css";
import GlobalStyles from "../components/atoms/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "../utils/styling";
import { useWindowSize } from "react-use";
import PledgeFormOverlay from "../components/molecules/PledgeFormOverlay";
import VolunteerFormOverlay from "../components/molecules/VolunteerFormOverlay";
import Header from "../components/molecules/Header";

function Index({ children, location }) {
  const size = useWindowSize();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--windowHeight",
      `${size.height}px`
    );
  }, [size]);

  // const [pageLoaded, setPageLoaded] = useState(false);
  //
  // // This will run one time after the component mounts
  // useEffect(() => {
  //   const onPageLoad = () => {
  //     setPageLoaded(true);
  //   };
  //
  //   // Check if the page has already loaded
  //   if (document.readyState === "complete") {
  //     onPageLoad();
  //   } else {
  //     window.addEventListener("load", onPageLoad);
  //     // Remove the event listener when component unmounts
  //     return () => window.removeEventListener("load", onPageLoad);
  //   }
  // }, []);
  //
  // useEffect(() => {
  //   console.log(pageLoaded);
  // }, [pageLoaded]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Header home={location.pathname === '/'}/>
        <main id="main">{children}</main>
        <PledgeFormOverlay />
        <VolunteerFormOverlay />
      </>
    </ThemeProvider>
  );
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;

